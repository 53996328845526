var BryApiModule = (function () {
  var BryApiModule = {};

  /**
   * Variável Global que facilita o acesso aos certificados.
   * A função que lista os certificados (BryApiModule.listCertificates) preenche esta variável.
   * Dessa forma, será possível pegar os certificados desta variável sempre que precisar.
   */
  BryApiModule.certificates = [];

  /**
   * Sempre que a página é carregada é solicitado à extensão que busque
   * todos os certificados do usuário.
   */

  BryApiModule.checkAndOpenBry = function () {
    // Passo 1 - Verifica se a extensão está instalada no navegador;
    BryWebExtension.isExtensionInstalled()
      .then((installed) => {
        if (installed) {
          // 1.1 - Extensão instalada no navegador!
          // $('#extensao-nao-instalada').close();

          console.log('Tem instalado');
          var event = new CustomEvent('build', {
            detail: 'openIsInstalled',
          });
          window.dispatchEvent(event);
          // window.openIsInstalled();
          return BryWebExtension.installComponents();

          // Passo 2 - Verifica se o módulo nativo está instalado e se necessário solicita que o usuário realize a instalação;

          // OU 2 - Verifica se o módulo nativo e os complementos de biometria estão instalados e se necessário solicita que o usuário realize a instalação;
          //return BryWebExtension.installComponents(additional=[{type:'fingerPrint'}]);

          // OU 2 - Apenas verifica se o módulo nativo está ou não instalado. Não solicita que o usuário realize a instalação;
          //return BryWebExtension.isNativeModuleInstalled();
        } else {
          // 1.2 - Extensão não instalada no navegador!
          console.log('Não tem instalado');
          // $('#extensao-instalada').hide();
          event = new CustomEvent('build', { detail: 'openNotInstalled' });
          window.dispatchEvent(event);
          // window.openNotInstalled();
          // $('#extensao-nao-instalada').show();

          // Detecta qual o navegador e exibe o link para instalação da extensão
          detectBrowser();
        }
      })
      .then((installed) => {
        // Passo 3 - lista os certificados e preenche o combo;

        console.log('then listCertificates');
        BryApiModule.listCertificates();
      })
      .catch((error) => {
        console.error('err', error);
        $('#codigo-de-erro').html(error.key);
        $('#error-message-text').text(error.description);
        $('#error-message').show();
        $('#success-message').hide();
        $('#update').hide();
      });
  };

  /**
   * Função utilizada para listar os certificados na máquina do usuário.
   */
  BryApiModule.listCertificates = function () {
    console.log('listCertificates triggered');
    // Função da extensão
    BryWebExtension.listCertificates()
      .then(function (certificates) {
        // Configura os certificados na página
        BryApiModule.updateCertificates(certificates);
      })
      .catch(function (error) {
        console.error(error);
        $('#codigo-de-erro').html(error.key);
        $('#error-message-text').text(error.description);
        $('#error-message').show();
        $('#success-message').hide();
        $('#update').hide();
      });
  };

  /**
   * Função utilizada para atualizar o combo de certificados. Antes de popular o combo esta função
   * aplica um conjunto de filtros.
   */
  BryApiModule.updateCertificates = function (certificates) {
    BryApiModule.certificates = BryApiModule.filter(certificates);
    BryApiModule.fillCertificateSelect(BryApiModule.certificates);
  };

  /**
   * Essa função é responsável por popular o elemento "select" na página
   * que o usuário utiliza para indicar qual o certificado deseja utilizar
   * para produzir a assinatura digital.
   *
   * Será utilizado as propriedades "name" e "certId" do certificado. Posteriormente,
   * será possível pegar o "certId" do certificado selecionado e consultar a variável
   * global de certificados para pegar os bytes do certificado.
   *
   */
  BryApiModule.fillCertificateSelect = function (certificates) {
    var element = $('#select-certificado-list');
    element.innerHTML = '';
    var event = '';
    var hasCertificateAvailable = certificates.length > 0;

    if (hasCertificateAvailable) {
      event = new CustomEvent('build', { detail: certificates });
      window.dispatchEvent(event);

      // BryApiModule.fillCertificateDataForm(certificates);
    }
  };

  /**
   * Realiza o processo de assinatura do input de entrada.
   *
   * É necessário informar o "certId" do certificado e os dados de entrada.
   * Para entender os dados de entrada do processo de assinatura favor consultar a documentação.
   */
  BryApiModule.sign = function (selectedCertificate, inputEntrada) {
    // Limpa o json de saída, setando "" string vazia a cada requisição.
    $('json-saida-valor').innerHTML = '';

    $('#json-entrada-valor').val(inputEntrada);

    // Função da extensão
    BryWebExtension.sign(
      selectedCertificate,
      JSON.stringify(inputEntrada, null, 2)
    )
      .then(function (response) {

        const event = new CustomEvent('build', {
          detail: { type: 'finalizeSign', data: response },
        });
        window.dispatchEvent(event);

        BryApiModule.processSignatures(response);

        console.log('sign', response);
      })
      .catch(function (error) {
        console.log('sign entrou no catch', error);
        const event = new CustomEvent('build', {
          detail: { type: 'finalizeSign', data: null },
        });
        window.dispatchEvent(event);
        $('#codigo-de-erro').html(error.key);
        $('#error-message-text').text(error.description);
        $('#error-message').show();
        $('#success-message').hide();
        $('#update').hide();
      });
  };

  /**
   * Essa função é chamada sempre que a página recebe devolta os
   * dados processados da assinatura. Esses dados precisam ser devolvidos
   * ao servidor para que a assinatura seja completada. Os dados
   * produzidos pela extensão são colocados em um "input" e então
   * o servidor é notificado que esses dados estão prontos.
   *
   * @param {Object} data - dados produzidos pela extensão.
   * Consulte a documentação do desenvolvedor.
   */
  BryApiModule.processSignatures = function (data) {
    console.log('processSignatures', data);

    $('#json-saida-valor').val(JSON.stringify(data));
    $('#success-message').show();
    $('#error-message').hide();
    $('#update').hide();

    console.log($('#json-saida-valor').val());
  };

  /**
   * Essa função é chamada sempre que o usuário altera sua opção de
   * qual certificado deseja utilizar.
   * Consulta a variável global de certificados para pegar informações do
   * certificado selecionado.
   */
  BryApiModule.fillCertificateDataForm = function (selected) {
    console.log('fillCertificateDataForm triggered', selected);

    console.log(selected);

    var certificate = null;
    for (var i = 0; i < BryApiModule.certificates.length; i++) {
      if (BryApiModule.certificates[i].certId === selected) {
        certificate = BryApiModule.certificates[i];

        $('#input-nome').val(certificate.name);
        $('#input-emissor').val(certificate.issuer);
        $('#input-data-validade').val(certificate.expirationDate);
        $('#input-tipo').val(certificate.certificateType);
        $('#input-dados-certificado').val(certificate.certificateData);
        break;
      }
    }
  };

  /**
   * Configurações utilizadas no momento que os filtros são aplicados.
   */
  BryApiModule.filters = {
    ROOT_CA: 0, // 0=Todos os certificados, 1=Somente Confiáveis, 2=Somente ICP-Brasil
    CNPJS: [], // ex.: ["CNPJ1","CNPJ2"]
    CPFS: [], // ex.: ["CPF","CPF2"]
    CERTIFICATE_TYPE: [], // ex.: ["A1","A2","A3"]
    SHOW_EXPIRED: true,
  };

  /**
   * Função responsável pela aplicação dos filtros. Essa função aplica
   * uma série de filtros aos certificados recebidos. Esses filtros são
   * aplicados um após o outro e apenas os certificados que satisfizerem
   * todos os filtros são retornados.
   *
   * @param {Array} certificates - certificados que serão filtrados.
   * @returns {Array} os certificados que satisfizeram todos os filtros.
   * Consulte a documentação do desenvolvedor.
   */
  BryApiModule.filter = function (certificates) {
    let filtered = BryApiModule.filterByCpf(certificates);
    filtered = BryApiModule.filterByCnpj(filtered);
    filtered = BryApiModule.filterByType(filtered);
    filtered = BryApiModule.filterExpired(filtered);
    filtered = BryApiModule.filterByRootCA(filtered);
    return filtered;
  };

  /**
   * Filtra os certificados através de uma lista de CPFs.
   *
   * @param {Array} certificates - Os certificados que devem ser filtrados
   * Consulte a documentação do desenvolvedor.
   */
  BryApiModule.filterByCpf = function (certificates) {
    var cpfs = BryApiModule.filters.CPFS;
    if (cpfs.length > 0)
      return certificates.filter(function (certificate) {
        return cpfs.find(function (cpf) {
          return onlyNumbers(cpf) === onlyNumbers(certificate.cpf);
        });
      });
    else return certificates;
  };

  /**
   * Filtra os certificados através de uma lista de CNPJs.
   *
   * @param {Array} certificates - Os certificados que devem ser filtrados
   * Consulte a documentação do desenvolvedor.
   */
  BryApiModule.filterByCnpj = function (certificates) {
    var cnpjs = BryApiModule.filters.CNPJS;
    if (cnpjs.length > 0)
      return certificates.filter(function (certificate) {
        return cnpjs.find(function (cnpj) {
          return onlyNumbers(cnpj) === onlyNumbers(certificate.cnpj);
        });
      });
    else return certificates;
  };

  /**
   * Filtro baseado no tipo do certificado.
   *
   * @param {Array} certificates - Os certificados que devem ser filtrados
   */
  BryApiModule.filterByType = function (certificates) {
    var types = BryApiModule.filters.CERTIFICATE_TYPE;
    if (types.length > 0)
      return certificates.filter(function (certificate) {
        return types.find(function (type) {
          return type === certificate.certificateType;
        });
      });
    else return certificates;
  };

  /** Habilita/desabilita a exibição de certificados expirados.
   * @param {Array} certificates - Os certificados que devem ser filtrados
   */
  BryApiModule.filterExpired = function (certificates) {
    if (BryApiModule.filters.SHOW_EXPIRED === false) {
      for (var i = certificates.length - 1; i >= 0; --i) {
        if (
          certificates[i].validity.localeCompare('VALID') != 0 &&
          certificates[i].validity.localeCompare('INVALID_INCOMPLET_CHAIN') != 0
        )
          certificates.splice(i, 1);
      }
    }
    return certificates;
  };

  /** Habilita/desabilita a exibição de certificados expirados.
   * @param {Array} certificates - Os certificados que devem ser filtrados
   */
  BryApiModule.filterByRootCA = function (certificates) {
    //0 é o comportamento padrão, ou seja, sem filtro
    var selectedValue = BryApiModule.filters.ROOT_CA;

    //Remove os certificados que não são confiáveis
    if (selectedValue == 1) {
      for (var i = certificates.length - 1; i >= 0; --i) {
        if (!certificates[i].trusted) certificates.splice(i, 1);
      }
      //Remove os certificados que não são ICP-Brasil
    } else if (selectedValue == 2) {
      for (var i = certificates.length - 1; i >= 0; --i) {
        if (!certificates[i].icpBrasil) certificates.splice(i, 1);
      }
    }

    return certificates;
  };

  /**
   * Remove todos os dígitos não-numéricos do campo informado
   * @param {any} field campo com o texto que deverá ser tratado
   * @returns {string} string contendo apenas os digitos númericos
   */
  function onlyNumbers(field) {
    return field.replace(/^\D+/g, '');
  }

  /**
   * Transforma a string de data retornada pela extensão em um objeto Date do javascript.
   * @param {any} date O formato esperado dessa string é "dd/MM/yyyy HH:mm:ss".
   * @returns {Date} objeto Date configurado com a data transformada da string.
   */
  function parseDate(date) {
    var slicedDate = date.split(' ');
    var dayMothYear = slicedDate[0].split('/');
    var hourMinuteSecond = slicedDate[1].split(':');
    return new Date(
      dayMothYear[2],
      dayMothYear[1] - 1,
      dayMothYear[0],
      hourMinuteSecond[0],
      hourMinuteSecond[1],
      hourMinuteSecond[2]
    );
  }

  /**
   * Funções simplificadas para detecção do browser, modifique se achar necessário
   */
  function isOpera() {
    return !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }

  function isFirefox() {
    return typeof InstallTrigger !== 'undefined';
  }

  function isSafari() {
    return navigator.userAgent.indexOf('Safari') > -1;
  }

  function isIE() {
    return /*@cc_on!@*/ false || !!document.documentMode;
  }

  function isEdge() {
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  function isChrome() {
    return /Google Inc/.test(navigator.vendor);
  }

  function detectBrowser() {
    $('#chrome-browser').hide();
    $('#firefox-browser').hide();
    $('#opera-browser').hide();
    $('#edge-browser').hide();
    $('#safari-browser').hide();
    $('#ie-browser').hide();
    $('#unknown-browser').hide();

    if (isChrome()) {
      $('#chrome-browser').show();
    } else if (isFirefox()) {
      $('#firefox-browser').show();
    } else if (isEdge()) {
      $('#edge-browser').show();
    } else if (isOpera()) {
      $('#opera-browser').show();
    } else if (isSafari()) {
      $('#safari-browser').show();
    } else if (isIE()) {
      $('#ie-browser').hide();
    } else {
      $('#unknown-browser').hide();
    }
  }

  BryApiModule.installExtension = function () {
    if (isChrome()) {
      window.addEventListener('focus', () => window.location.reload());
      window.open(
        'https://chrome.google.com/webstore/detail/mbpaklahifpfndjiefdfjhmkefppocfm'
      );
    } else if (isSafari()) {
      alert(
        'Navegador Safari não é suportado! Favor utilizar Google Chrome ou Mozilla Firefox'
      );
    } else {
      function timeout() {
        setTimeout(function () {
          if (BryWebExtension.isExtensionInstalled()) {
            window.location.reload();
          }
          timeout();
        }, 1000);
      }

      timeout();
    }
  };

  return BryApiModule;
})();
